
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




























































































































































































































































































































































































































[data-template='homepage'] {
  .myvoo {
    @include mq(l) {
      margin-bottom: $spacing * 1.5;
    }

    @include mq(xl) {
      margin-bottom: $spacing * 5;
    }
  }

  .footer__nav {
    @include mq(l) {
      padding-top: 0;
    }
  }

  [class*='--small-title'],
  [class*='--countdown'] {
    .hero__slides__item__excerpt-outer {
      margin-top: 0;
    }

    .hero__slides__item__title {
      display: inline-block;
      width: auto;
      margin-right: auto;
      margin-bottom: 1.5rem;
      margin-left: auto;
      font-size: 2.8rem;
      line-height: 0.9;

      span {
        padding-left: 1.5rem;
      }

      b {
        font-size: 6.8rem;
      }
    }

    @include mq(l) {
      .hero__slides__item__title {
        margin-bottom: 3rem;
        font-size: 4.2rem !important;
        line-height: 1.1;

        span {
          padding-left: 2rem;
        }

        b {
          font-size: 12rem;
        }
      }
    }
  }

  // MULTIPLE SLIDE WITH DIFFERENTS BG
  .voo-header.is-light {
    .voo-logo {
      @include mq(l) {
        .icon {
          fill: $white;
        }
      }
    }

    // Change links color to white but does NOT affect links within dropdowns as the dropdown background is white
    .voo-menu__list__item a:not(.voo-submenu-dropdown__list__item__link) {
      @include mq(l) {
        color: $white !important;

        &:hover {
          color: $c-pink-medium !important;
        }
      }
    }
  }
}
