
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


















































































































































































































































































































.vod-highlight,
[class*='vod-highlight--'] {
  position: relative;
  background-color: $c-blue-dark;
}

.vod-highlight__speedlines {
  position: absolute;
  z-index: layer(high);
  top: 0;
  right: 0;
  width: 100%;
  max-width: 42rem;
  height: 110%;
  transform: translateY(-50%);

  ::v-deep {
    .speedlines__item,
    [class*='speedlines__item--'] {
      @include mq($until: m) {
        opacity: 0;
      }
    }
  }
}

.vod-highlight__picture {
  width: 100%;

  @include mq(m) {
    @include image-fit;

    object-position: 0% 0%;
  }
}

.vod-highlight-inner {
  @extend %color-light;

  position: relative;
  display: flex;
  flex-direction: column;

  @include mq(m) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.vod-highlight__infos-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq($until: l) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-top: $spacing * 2;
  }

  .divertissez-voo__highlight & {
    justify-content: space-around;
    padding-top: $spacing / 2;

    @include mq($until: l) {
      flex-direction: column-reverse;
    }

    @include mq(l) {
      flex-direction: row-reverse;
      width: 100%;
      margin-top: calc(68px - 1rem);
    }
  }
}

.vod-highlight__infos {
  position: relative;
  z-index: layer(lower);
  margin: 3rem 0 25%;

  &:not(.divertissez-voo__highlight &) {
    @include mq(m) {
      width: col(5);
      margin: 12rem 0 7rem;
    }
  }

  .divertissez-voo__highlight & {
    width: col(12);

    @include mq(l) {
      width: 36%;
      margin: 0;
    }
  }
}

.vod-highlight__buttons {
  display: flex;
  align-items: center;
  margin-bottom: 6rem;
  gap: $spacing;

  .divertissez-voo__highlight & {
    margin-bottom: 0;

    .btn--no-icon {
      padding: 12px 30px;

      ::v-deep .btn__link__title {
        @include mq($until: m) {
          font-size: 18px;
        }
      }
    }

    .link--white {
      @include mq($until: m) {
        font-size: 18px;
      }
    }
  }

  // Spacing horizontally direct child following eachother
  // > * + * {
  // margin-left: $spacing;
  // }
}

.vod-highlight__play-btn {
  display: flex;
  transition: transform 0.3s ease-out;

  &:hover {
    @include mq(m) {
      transform: scale(1.1);
    }
  }
}

.vod-highlight__play {
  // @include get-all-space;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 2rem;
  text-decoration: none;
  border: 1px solid $white;
  border-radius: 5rem;

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.05);
    }
  }

  @include mq($until: l) {
    padding: 0.4rem 1rem;
    font-size: 1.5rem;
  }
}

.vod-highlight__play__label {
  @extend %fw-bold;

  color: $white;
  font-family: $ff-alt;
  font-size: 1.5rem;
  text-decoration: none;

  @include mq(m) {
    font-size: 1.8rem;
  }
}

.vod-highlight__infos .icon img {
  width: 46.3rem;
  margin-bottom: 3.6rem;
}

.vod-highlight__infos__label {
  display: inline-block;
  margin-bottom: $spacing;
  font-family: $ff-alt;
  font-size: 1.4rem;
  text-transform: uppercase;
  border-radius: 3px;

  &:not(.divertissez-voo__highlight &) {
    @extend %fw-black;

    padding: $spacing / 4 $spacing / 2;
    color: $white;
    background-color: $c-pink-medium;
  }

  .divertissez-voo__highlight & {
    @extend %fw-semi;

    padding: 9px 14px;
    border: 1px solid $c-bunting;
  }
}

.vod-highlight__infos__title {
  margin: 0 0 3rem;

  ::v-deep {
    img {
      width: 9.5rem;
    }
  }
}

.vod-highlight__infos__text {
  margin: 0 0 4.5rem;
}

.vod-highlight__infos__logo {
  max-width: 150px;
  margin-bottom: $spacing * 2;
}

.vod-highlight__poster {
  width: col(6);
  margin-bottom: 0;

  .divertissez-voo__highlight & {
    width: 100%;

    img {
      width: 100%;
      background-color: #fff;
      border-radius: 12px;
    }

    @include mq(l) {
      width: col(6);
      max-width: 50rem;
    }
  }
}

.vod-highlight__card__order {
  cursor: pointer;
}

.vod-highlight__heart-filled-pink {
  fill: $c-pink-medium;
  position: absolute;
  right: 11.6rem;
  bottom: 35.1rem;
  transform: rotate(7.9deg);
}

.vod-highlight__heart-filled-blue {
  fill: $c-cyan-contrast;
  position: absolute;
  right: 36.2rem;
  bottom: 24.2rem;
  transform: rotate(-7.4deg);
  width: 8.2rem;

  @include mq(m) {
    right: 30.2rem;
    bottom: 41.2rem;
  }
}

.vod-highlight__heart-voo-light {
  fill: $c-pink-lightest;
  position: absolute;
  right: 6.2rem;
  bottom: 5rem;
  transform: rotate(-8.58deg);

  @include mq(m) {
    right: 6.2rem;
  }
}

.vod-highlight__heart-voo {
  fill: $c-pink-medium;
  position: absolute;
  bottom: 4.3rem;
  left: 5rem;
  transform: rotate(4.12deg);
  width: 8.2rem;

  @include mq(m) {
    right: 80.2rem;
    bottom: 2.3rem;
    left: auto;
  }
}

.vod-highlight-inner-title {
  width: 100%;
  text-align: center;

  h2 {
    margin-top: 0;
    margin-bottom: 22px;
  }

  p {
    font-size: 22px;
  }
}

.vod-highlight__more-infos {
  p {
    margin-top: 0;
  }

  .more-infos__category {
    @extend %fw-semi;
  }

  .more-infos__duree {
    @extend %fw-normal;

    margin-left: 14px;
    color: $c-blue-pale;
  }
}

.btn--no-icon {
  margin-top: 0;
}
