
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        















































































































































































































































































































































































/* stylelint-disable no-descending-specificity */
/* stylelint-disable declaration-no-important */
$bp: m;

.total-price {
  position: fixed;
  z-index: 20;
  right: 0;
  bottom: 8rem;
  max-width: 30rem;
  padding: 2rem;
  background-color: $gray-lightest;
}

.configurator {
  &.is-solo {
    label.switch {
      display: none;
    }
  }

  @include mq(l) {
    display: grid;
    grid-template-columns: minmax(auto, 72rem) minmax(auto, 38rem);
    gap: 5rem;
    justify-content: space-between;
    align-items: start;
  }
}

.configurator__page-title {
  margin: 0 0 $spacing;

  @include mq($bp) {
    grid-column: span 2;
  }
}

.configurator__switchto {
  position: relative;
  margin: 0 -2rem 4.5rem;
  padding: 3rem 2rem;
  background-color: $c-cultured;

  @include mq(m) {
    margin: 0 -7rem 4.5rem;
    padding: 3rem 7rem;
  }

  @include mq($bp) {
    display: flex;
    align-items: center;
    margin: 0 0 4.5rem;
    padding: 3rem 2rem;
    border-radius: 2px;
  }
}

.configurator__switchto__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.configurator__switchto__speedlines {
  position: absolute;
  top: -2rem;
  right: 5rem;
  width: 7rem;

  @include mq($bp) {
    display: none;
  }
}

.configurator__switchto__picture {
  flex-shrink: 0;
  width: auto;
  height: 8rem;
  margin-bottom: 1.5rem;

  @include mq($bp) {
    width: 10rem;
    height: auto;
    margin-right: 1.5rem;
  }
}

.configurator__switchto__title {
  @extend %fw-bold;

  font-family: $ff-alt;
  font-size: 2.1rem;
  line-height: 2.4rem;
}

.configurator__switchto__text {
  margin-top: 0;

  p {
    margin: 0;
  }
}

.configurator__sidebar {
  @include mq(l) {
    position: sticky !important;
    top: 10rem;
    min-width: 39rem;
    border-radius: 2rem;
  }

  @include mq($until: l) {
    .configurator__sidebar__link-wrapper.is-desktop {
      display: none;
    }
  }
}

.configurator__sidebar__link-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacing;

  .icon {
    width: 4rem;
    height: 4rem;
    margin-right: $spacing * 0.75;
    fill: $c-pink-medium;
  }
}

.configurator__sidebar__link {
  text-align: center;
  text-decoration: none;

  // @include mq($until: l) {
  //   margin-top: 4rem;
  //   padding-top: 1.6rem;
  //   padding-bottom: 1.6rem;
  //   text-align: left;
  //   border-top: 1px solid $c-gallery;
  //   border-bottom: 1px solid $c-gallery;
  // }
}

.configurator__sidebar__speedlines {
  z-index: -1;
  transform: translate(-20%, -30%);
  overflow: hidden; /* Prevent page scroll horizontal */

  @include mq($until: l) {
    display: none;
  }
}

.configurator__offer {
  @include mq(l) {
    border-radius: 2rem;
  }
}

.configurator__sidebar__advantages {
  &.voo-advantages {
    flex-basis: 100%;
    width: 100%;
    margin: 5rem 0 0;
    padding: 0;
  }

  .voo-advantages__title {
    margin-bottom: 2.4rem;
    font-size: 2.1rem;
  }

  .voo-advantages-container {
    @include mq(l) {
      padding: 2.5rem;
      border-radius: 10px;
      box-shadow: 0 15px 40px rgba($black, 0.15);
    }
  }

  .voo-advantages__list {
    margin-bottom: 2.5rem;
  }

  .voo-advantages__list__item {
    align-items: center;
    margin-bottom: 1rem;

    .icon {
      width: 2.5rem;
      height: 2.5rem;
    }

    @include mq(l) {
      .icon {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
      }
    }
  }

  .voo-advantages__list__item__text {
    font-size: 1.4rem;
    line-height: 2rem;

    @include mq(l) {
      font-size: 1.6rem;
    }
  }
}

.configurator__not-active {
  color: $c-anthracite-light;
  font-family: $ff-default;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;

  @include mq($bp) {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.configurator__error {
  display: flex;
  align-items: center;
  color: $c-pink-medium;
  font-size: 3rem;

  img {
    height: 20rem;
  }
}

.configurator__button,
[class*='configurator__button--'] {
  padding: 0;
  background-color: transparent;
  border: 0;
  transition: opacity 0.15s;
  cursor: pointer;

  &[class*='--center'] {
    display: flex;
    margin-inline: auto;
    margin-top: $spacing;
  }

  &:hover {
    opacity: 0.7;
  }
}

.configurator__headline {
  @extend %fw-semi;
  @extend %text-uppercase;

  display: block;
  margin-bottom: $spacing * 0.5;
  color: $c-promo-card-headline;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 2px;

  @include mq($bp) {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
}

.configurator__label {
  @extend %fw-bold;
  @include fluid(
    font-size,
    (
      s: 14px,
      xxl: 15px,
    )
  );

  display: block;
  margin-bottom: 1rem;
  color: $c-promo-card;
  line-height: 2rem;

  @include mq($bp) {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
  }
}

.configurator__title {
  @extend %fw-bold;
  @include fluid(
    font-size,
    (
      s: 16px,
      xxl: 24px,
    )
  );

  display: block;
  margin-bottom: $spacing * 0.5;
  color: $c-promo-card;
  font-family: $ff-alt;
  line-height: 2.4rem;

  @include mq($bp) {
    line-height: 3rem;
  }
}

.configurator__text {
  margin-bottom: 1rem;
  color: $c-blue-dark;
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;

  p {
    margin: 0;
  }

  @include mq($bp) {
    margin-bottom: 1.5rem;
    font-size: 1.6rem;
    line-height: 2.3rem;
  }
}

.configurator__small {
  display: inline-block !important;
  color: $c-anthracite-light !important;
  font-family: $ff-default;
  font-size: 1.4rem !important;
  font-weight: 400;
  line-height: 1.6rem !important;
  border-bottom: 1px solid rgba($c-anthracite-light, 0.15) !important;

  @include mq($bp) {
    font-size: 1.6rem !important;
    line-height: 2rem !important;
  }
}

.configurator__section {
  margin-bottom: 5rem;

  &.is-disabled {
    user-select: none;
  }

  &:hover {
    &.is-disabled {
      cursor: not-allowed;
    }
  }

  @include mq($bp) {
    margin-bottom: 7rem;
  }
}

.configurator__section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  line-height: 2.3rem;
  border-bottom: 1px solid $c-light-gray;

  // @include mq($bp) {
  //   margin: 0 0 5rem;
  // }
}

.configurator__section__header__title {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 2rem;
  line-height: 2rem;

  @include mq($bp) {
    font-size: 3.6rem;
    line-height: 3rem;
  }
}

.configurator__section__rgus,
[class*='configurator__section__rgus--'] {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  &[class*='--large'] {
    grid-template-columns: repeat(auto-fill, minmax(16.5rem, 1fr));
  }

  &[class*='--small'] {
    grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
  }

  .is-disabled & {
    pointer-events: none;
  }

  @include mq($bp) {
    gap: 2.5rem;
  }
}

.configurator__section__rgu {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 34rem;

  .selectable-block {
    width: 100%;
    height: 100%;
  }

  [class*='configurator__section__rgus--'][class*='--large'] & {
    max-width: 22.5rem;
  }
}

.configurator__section__options {
  margin-bottom: 3.5rem;
}

.configurator__info {
  @extend %text-left;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem !important;
  font-family: $ff-alt !important;
  font-size: 1.4rem !important;
  line-height: 16px !important;
  background-color: $c-cultured !important;
  border-radius: 10px !important;

  @include mq($bp) {
    font-size: 1.8rem !important;
    line-height: 24px !important;
  }
}

.configurator__info__icon {
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: auto;

  span {
    margin-right: 2rem;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: $c-midnight-darker;
  }
}

.configurator__extra {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @include mq($bp) {
    margin-bottom: 3rem;
  }
}

.configurator__extra__item {
  flex-shrink: 0;

  .icon {
    width: 1.5rem;
    height: 1.9rem;
    margin-right: 1rem;
  }

  @include mq($bp) {
    font-size: 2.4rem;

    .icon {
      width: 2.1rem;
      height: 2.7rem;
    }
  }
}

.configurator__extra__add {
  display: flex;
  align-items: center;
  margin: 4rem auto 0;
  line-height: 1;

  strong {
    color: $c-mandarine;
  }

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
    fill: $c-promo-card;
    transition: fill 0.15s;
  }

  &:hover {
    opacity: 1;

    .icon {
      fill: $c-pink-medium;
    }
  }

  @include mq($bp) {
    margin: 4rem 0 0;
  }
}

.configurator__extra__remove {
  display: flex;
  align-items: center;
  color: $c-anthracite-light;
  line-height: 1;
  transition: opacity 0.25s;

  span {
    border: 0;
  }

  &:hover {
    opacity: 0.7;
  }
}

.configurator__extra__remove__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
  padding: 2px;
  background-color: $c-weldon-blue;
  border-radius: 50%;

  .icon {
    width: 100%;
    height: 100%;
    fill: $white;
    transition: fill 0.25s;
  }
}

.configuration__sections__promo {
  display: none; // TEMP
  margin-bottom: $spacing * 2;
}

.configurator__compare-button {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
