
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






























































































































































































$bp: m;

.mobile__included {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5rem;
  color: $c-anthracite-light;

  .icon {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
    fill: $c-anthracite-light;
  }

  @include mq($bp) {
    margin-bottom: 4.5rem;
  }
}

.net__rgu__spec {
  margin-bottom: 1rem;
}

.net__rgu__spec__price {
  margin-bottom: 1.5rem;
}

.configurator__section__header__icon {
  width: 3.8rem;
  height: 2rem;
  margin-right: 1rem;

  @include mq($bp) {
    width: 4.8rem;
    height: 2.6rem;
  }
}

.net__availability {
  display: block;
  margin: 2.5rem 0 0;

  @include mq($bp) {
    margin: 4.5rem 0 0;
  }
}

.configurator__special-edition {
  position: relative;
  margin: $spacing * 2 0;
  padding: 1.6rem 3rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  background-color: $white;
  border: 1px solid $c-orange;
  border-radius: 10px;
}

.configurator__special-edition-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ::v-deep {
    p {
      margin: 0;
    }
  }
}

.configurator__special-edition__label {
  position: absolute;
  top: 0;
  left: $spacing;
  transform: translateY(-50%);
  padding: 0.5rem 0.6rem;
  font-size: 0.9rem;
}

.configurator__special-edition__icon {
  margin-left: 3rem;
  font-size: 3.2rem;
  line-height: 1;
}
