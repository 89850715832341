
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



















































































































































































































































































































































































































































































.hardware-slider {
  position: relative;
  width: 100%;
  max-width: 133.5rem;
  margin-top: 0;
  color: $white;
}

.hardware-slider-inner {
  position: relative;
  z-index: 1;

  .is-slider & {
    padding-top: 100%;

    @include mq(xxs) {
      padding-top: 110%;
    }

    @include mq(l) {
      padding-top: 50%;
    }
  }
}

.hardware-slider__title {
  top: 0;
  margin: $spacing auto;
  text-align: center;

  .is-slider & {
    @include center-x;

    margin: 0 auto;
  }

  @include mq($until: l) {
    top: -$spacing;
    font-size: 1.3rem;
  }
}

.hardware-slider__items {
  @extend %list-nostyle;

  z-index: 1;
  display: flex;
  justify-content: space-between;
  gap: $spacing;
  width: 100%;
  height: auto !important; // stylelint-disable declaration-no-important

  &.is-solo {
    justify-content: center;
  }

  @include mq($until: l) {
    .is-slider & {
      display: block;
    }
  }
}

.hardware-slider__item {
  flex: 1;
  max-width: 40rem;

  &.unavailable {
    pointer-events: none;
  }

  @include mq(l) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .is-slider & {
      max-width: 32rem;

      & + & {
        margin-left: $spacing;
      }
    }
  }

  @include mq(xl) {
    & + & {
      margin-left: $spacing * 2;
    }
  }

  @include mq(xxl) {
    & + & {
      margin-left: $spacing * 3;
    }
  }
}

.hardware-slider__item__picture {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 15rem;
  transition: cubic-bezier(0.44, 0, 0, 1) 1s;
  cursor: pointer;

  img {
    @include image-fit(contain);

    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  .unavailable & {
    pointer-events: none;

    img {
      opacity: 0.7;
    }
  }

  .is-slider & {
    position: absolute;
    bottom: 15%;
    left: 0;
    width: 50%;
    height: 60%;
    transform: translateX(-50%);
  }

  .is-center & {
    z-index: 2;
    left: 50%;
    width: 95%;
    height: 85%;
  }

  .is-right & {
    z-index: 0;
    left: 100%;
  }

  @include mq(s) {
    height: 25rem;
  }

  @include mq(l) {
    height: 40rem;

    .is-left & {
      left: 15%;
    }

    .is-right & {
      left: 85%;
    }

    .is-slider & {
      &:hover {
        width: 53%;
        height: 63%;
        transition: cubic-bezier(0.44, 0, 0, 1) 0.5s;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .is-center & {
      width: 55%;
      height: 80%;

      &:hover {
        width: 58%;
        height: 82%;
      }
    }
  }
}

.hardware-slider__item__content {
  @extend %button-nostyle;

  z-index: 1;
  width: 100%;
  padding: 0.9rem 0 1.3rem;
  color: $white;
  text-align: center;
  transition: 0.5s;

  .is-slider & {
    @include center-x;

    bottom: -$spacing;
    width: 90%;
    opacity: 0;
    visibility: hidden;
  }

  .is-slider .is-active & {
    opacity: 1;
    visibility: visible;
  }

  @include mq(l) {
    padding: 1.4rem 0 1.8rem;

    .is-left & {
      left: 0;
      transform: translateX(-5%);
    }

    .is-right & {
      transform: translateX(55%);
    }

    .is-slider & {
      bottom: 0;
      width: 33%;
      opacity: 1;
      visibility: visible;

      .is-business & {
        min-height: 8rem;
      }
    }
  }
}

.hardware-slider__item__content__title {
  @extend %fw-ultra;
  @extend %text-uppercase;

  font-family: $ff-alt;
  font-size: 1.5rem;
  line-height: 2rem;
}

.hardware-slider__item__content__text {
  @extend %fw-normal;

  font-size: 1.4rem;
  line-height: 1.3;
  opacity: 0.5;
}

.hardware-slider__indicator {
  position: absolute;
  z-index: -1;
  bottom: -$spacing;
  left: 50%;
  overflow: hidden;
  width: 100%;
  height: 8rem;
  min-height: 7rem;
  border-radius: 1.2rem;
  transform: translateX(-50%);

  .is-slider & {
    background: #030e1b;
  }

  @include mq(l) {
    bottom: 0;
  }
}

.hardware-slider__indicator__progress {
  @include get-all-space;

  background: rgba(#25334a, 0.7);
  transform: scaleX(0);
  transform-origin: left;
}

.hardware-slider__separator {
  @extend %fw-ultra;
  @extend %text-center;
  @extend %text-uppercase;
  @include center-xy;

  width: 5.5rem;
  height: 5.5rem;
  color: $c-promo-card;
  font-family: $ff-alt;
  line-height: 5.5rem;
  background: $c-light;
  border-radius: 50%;

  @include mq(l) {
    width: 10.3rem;
    height: 10.3rem;
    font-size: 2.6rem;
    line-height: 10.3rem;
  }
}

.hardware-slider__countdown {
  position: relative;
  z-index: 1;
  margin: $spacing * 3 0 $spacing * 2;

  .countdown {
    margin: auto;
  }

  @include mq(l) {
    margin: $spacing * 3 0;
  }
}

.hardware-slider__link {
  position: relative;
  z-index: 1;
  margin-top: $spacing * 2;
  text-align: center;

  @include mq(l) {
    margin-top: $spacing * 3;
  }
}

.hardware-slider__speedlines {
  @include get-all-space;

  ::v-deep .speedlines {
    @include get-all-space;

    transition: opacity 1s;

    &.invisible {
      opacity: 0;
    }
  }
}

.hardware-slider__unavailable {
  @extend %text-center;
  @include center-xy;

  z-index: 4;
  display: none;
  padding: 2rem;
  color: $c-blue-dark;
  font-family: $ff-alt;
  font-size: 1.8rem;
  line-height: 1.2;
  text-align: center;
  background-color: $white;
  border-radius: 8px;

  .unavailable & {
    display: block;
  }

  strong {
    @extend %fw-black;

    display: block;
    white-space: nowrap;
  }

  .blue & {
    background: $c-light-blue;
  }

  .cyan & {
    background: $c-cyan-contrast;
  }

  .orange & {
    background: $c-orange;
  }

  .burgundy & {
    background: $c-burgundy;
  }

  .midnightblue & {
    background: $c-blue-dark;
  }

  .pink & {
    background: $c-pink-light;
  }
}

.hardware-slider__counter {
  ::v-deep [class*='label'] {
    white-space: pre-wrap;
  }

  &.is-desktop {
    position: absolute;
    top: 50%;
    left: 0;
    display: none;
    width: 16rem;
    transform: translateY(-50%);

    ::v-deep [class*='label'] {
      padding: 1rem 1.5rem;
      font-weight: 500;
      line-height: 1.25;
      text-transform: none;

      @include mq(m) {
        padding: 1rem 1.75rem;
      }
    }

    @include mq(s) {
      display: block;
    }

    @include mq(m) {
      top: 50%;
      left: 30%;
      transform: translate(-50%, -50%);
    }

    @include mq(l) {
      left: 15%;
      width: 22rem;
    }
  }

  &.is-mobile {
    display: block;
    width: auto;
    text-align: center;

    ::v-deep [class*='label'] {
      padding: 0.75rem 1rem 0.5rem;
      font-weight: 900;
      line-height: 1.15;
      text-transform: uppercase;
    }

    @include mq(s) {
      display: none;
    }
  }
}
