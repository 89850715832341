
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




















































.eligibility-address,
[class*='eligibility-address--'] {
  @extend %fw-bold;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
  padding: 1.5rem 2rem;
  font-size: 1.6rem;
  border: 1px solid $c-blue-light-pale;
  border-radius: 8px;

  @include mq(m) {
    margin-bottom: 4rem;
    font-size: 2rem;
  }
}
