
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





















































































































































.panel-zuny,
[class*='panel-zuny--'] {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  max-width: 70rem;
  min-height: 100%;
  padding: 6rem 1.5rem 2rem;
  background: $c-light;

  * {
    font-family: $ff-zuny;
  }

  @include mq('s') {
    padding: 6rem 6rem 4rem;
  }
}

.panel-zuny__section {
  margin-bottom: $spacing;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq('xs') {
    margin-bottom: $spacing * 1.5;
  }

  @include mq('s') {
    margin-bottom: $spacing * 2;
  }
}

.heading__logo {
  height: 3.2rem;
  width: auto;
}

.heading__title {
  margin: 0;
}

.duo__video {
  @extend %box-shadow;

  width: 100%;
  border-radius: 1.5rem;

  @include mq('s') {
    border-radius: 3rem;
  }
}

.duo__video-inner {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $black;
  border-radius: 1.5rem;

  @include mq('s') {
    align-items: stretch;
    flex-direction: row;
    aspect-ratio: 16 / 9;
    border-radius: 3rem;
  }
}

.duo__video__file {
  display: none;
  aspect-ratio: 16 / 9;

  ::v-deep .plyr--video {
    background: $c-light;
  }

  ::v-deep .plyr__video-wrapper {
    background: $c-light;
  }

  @include mq('s') {
    @include get-all-space;

    aspect-ratio: unset;
  }
}

.duo__video__thumbnail,
.duo__video__text {
  @include mq('s') {
    flex-basis: 50%;
    width: 50%;
  }
}

.duo__video__thumbnail {
  position: relative;
  overflow: hidden;
  height: 24rem;
  cursor: pointer;

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.1);
    transition: transform 0.25s ease;
  }

  .icon {
    @include center-xy;

    z-index: 1;
    fill: $c-light;
    height: 4rem;
    width: auto;
    transition: height 0.25s ease;
  }

  .duo__video:hover & {
    .img {
      transform: scale(1);
    }
    .icon {
      height: 5rem;
    }
  }

  @include mq('s') {
    height: auto;
  }
}

.duo__video__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $c-zuny-lighter;
  padding: 2.5rem;
  font-size: 2.4rem;

  ::v-deep p {
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.25;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq('s') {
      margin-bottom: 1rem;
    }
  }

  ::v-deep strong {
    font-weight: 900;
  }

  @include mq('s') {
    padding: 5rem 3rem;
    font-size: 3rem;
  }
}

.keyfacts__list {
  @extend %list-nostyle;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding: 1rem 0;

  @include mq('s') {
    justify-content: center;
    padding: 0;
  }
}

.keyfacts__list__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.keyfacts__list__item__icon {
  fill: $black;
  height: 2.5rem;
  margin-bottom: 1rem;
  width: auto;

  @include mq('s') {
    width: auto;
    height: 4rem;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.keyfacts__list__item__label {
  width: 10rem;
  line-height: 1;
  font-weight: 900;
  font-size: 1.4rem;
  text-align: center;

  @include mq('s') {
    width: 13rem;
    font-size: 1.8rem;
  }
}

.listing-outer {
  border-radius: 3rem;

  ::v-deep {
    .listing {
      background-color: $c-zuny-lighter;
      padding: 2.5rem;
      border-radius: 2.5rem;

      @include mq('s') {
        padding: 3rem;
      }
    }

    .listing__list__item__icon {
      width: 2rem;
      height: 2rem;
      fill: $black;

      &.is-gray {
        fill: $c-light;
      }

      @include mq('s') {
        width: 2.6rem;
        height: 2.6rem;
      }
    }

    .listing__list__item__text {
      font-size: 1.8rem;
      font-weight: 700;
      font-family: $ff-zuny;

      @include mq('s') {
        font-size: 2.4rem;
      }
    }
  }
}

.cta-outer {
  display: flex;
  justify-content: center;

  .cta {
    padding-right: 5rem;
    padding-left: 5rem;
    background-color: $black;
    font-weight: 700;

    ::v-deep .btn__link__title {
      font-family: $ff-zuny;
      font-weight: 900;

      .cta:hover & {
        color: $c-zuny-lighter;
      }
    }
  }
}
