
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



































































































































































































































































































































































































































































/* stylelint-disable declaration-no-important */
$bp: m;

.offer {
  width: auto;

  ::v-deep .product-card__details {
    color: $c-light;
    background-color: $c-bunting;
  }

  @include mq($until: l) {
    ::v-deep .product-card__details {
      position: fixed;
      z-index: 10;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1.5rem 2rem;
    }

    ::v-deep .product-card__inner {
      overflow: visible;
      border-radius: 0;
      box-shadow: none;
    }

    ::v-deep .product-card__main {
      padding: 0;
    }
  }

  @include mq(l) {
    ::v-deep .product-card__inner {
      overflow: hidden;
    }

    ::v-deep .product-card__main {
      @include scrollbars(5px, $c-gray-lightest);

      overflow: auto;
      max-height: calc(100vh - 20rem);
      padding: 2.5rem;
    }

    ::v-deep .product-card__details {
      padding: 2rem 3rem;
    }
  }
}

.offer__title {
  @extend %fw-bold;

  display: block;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-family: $ff-alt;
  font-size: 2.4rem;
  line-height: 1;
  border-bottom: 1px solid $c-gallery;

  // @include mq($bp) {
  //   font-size: 3.2rem;
  // }
}

.offer__error {
  padding: 4rem 0 0;
  font-family: $ff-alt;
  text-align: center;
}

.offer__error__title {
  margin-bottom: 2rem;
  font-size: 2.1rem;
}

.offer__error__text {
  @extend %fw-medium;

  font-size: 1.8rem;
}

.offer__selection {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  height: auto !important;

  ::v-deep {
    .selection__products {
      margin-top: 0 !important;
    }

    .selection__products__figure__icon {
      max-width: 4rem;
      max-height: 2rem;
    }

    .selection__products__figure__title {
      font-size: 0.8rem;
    }

    .selection__products__figure {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

.offer__promo-group {
  position: relative;
  margin-top: $spacing * 1.25;
  padding: $spacing;
  background: #f5f5f5;
  border-radius: 6px;

  ul {
    @extend %list-nostyle;

    font-size: 1.4rem;
    font-weight: 400;
  }
}

.offer__promo-group__label {
  position: absolute;
  top: 0;
  left: $spacing;
  transform: translateY(-50%);
  padding: 0.5rem 0.6rem;
  font-size: 0.9rem;
}

.offer__section {
  margin-bottom: 3rem;
}

.offer__section__title {
  @extend %fw-bold;

  display: block;
  margin-bottom: 1.5rem;
  font-family: $ff-alt;
  font-size: 2rem;
  border-bottom: 1px solid $c-gallery;
}

.offer__section__row {
  display: flex;
  justify-content: space-between;
  line-height: 1;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @include mq($bp) {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}

.offer__section__row__label {
  color: $c-anthracite-light;
  font-size: 1.5rem;

  ::v-deep p {
    margin: 0;
  }
}

.offer__section__row__value {
  @extend %fw-bold;

  font-size: 1.6rem;

  b {
    display: inline-block;
    min-width: 53px;
    color: $c-mandarine;
    text-align: right;
  }
}

.offer__extras {
  margin: 0;
}

.offer__extras__title {
  @extend %fw-bold;

  display: block;
  margin-bottom: 1rem;
  font-family: $ff-alt;
  font-size: 1.6rem;
}

.offer__extras__list {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;

    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
}

.offer__extras__list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: $c-anthracite-light;
  font-size: 1.5rem;
  line-height: 1;

  .icon {
    width: 1.8rem;
    height: 1.8rem;
    fill: $c-anthracite-light;
  }

  @include mq($bp) {
    .icon {
      width: 2rem;
      height: 2rem;
    }
  }
}

.offer__footer {
  display: flex;
  justify-content: space-between;
  line-height: 1;
}

.offer__footer__error {
  width: 100%;
  line-height: 1.3em;

  @include mq($bp) {
    display: none;
  }
}

.offer__footer__error__title {
  display: block;
  max-width: 30rem;
  margin: 0 auto;
  color: $c-blue-light-pale;
  text-align: center;

  @include mq($bp) {
    color: inherit;
  }
}

.offer__footer__headline {
  @extend %fw-medium;

  color: $c-blue-light-pale;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.offer__footer__price {
  display: flex;
  align-items: center;
  font-family: $ff-alt;
}

.offer__footer__price__final {
  @extend %fw-bold;

  margin-right: 1rem;
  color: $c-mandarine;
  font-size: 2.2rem;
}

.offer__footer__price__original {
  @extend %fw-medium;

  position: relative;
  margin-top: 2px;
  color: $white;
  font-size: 1.3rem;

  &::before {
    @include center-y;

    content: '';
    left: -10%;
    width: 120%;
    height: 3px;
    background-color: rgba($c-mandarine, 0.7);
    border-radius: 3px;
  }
}

.offer__footer__btn {
  margin-left: auto;

  @include mq($until: $bp) {
    &.is-disabled {
      display: none;
    }
  }
}
