
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









.markee {
  @extend %fw-black;
  @extend %text-uppercase;

  overflow: hidden;
  font-family: $ff-alt;
  font-size: 11rem;
  white-space: nowrap;
  user-select: none;

  @include mq(l) {
    font-size: 30rem;
  }

  @include mq(xxl) {
    font-size: 40rem;
  }
}

.markee__inner {
  --shadow-distance: 0;

  display: inline-block;
  padding-right: 1em;
  color: $c-light;
  line-height: 1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px $c-light;
  animation: markee linear 300s infinite;
}

@keyframes markee {
  0% {
    transform: translateX(20%);
  }

  100% {
    transform: translateX(-100%);
  }
}
