
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        







































































































































































































































































































































































$bp: m;

.mobile__included {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5rem;
  color: $c-anthracite-light;

  .icon {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
    fill: $c-anthracite-light;
  }

  @include mq($bp) {
    margin-bottom: 4.5rem;
  }
}

.mobile__sim {
  &:not(:last-child) {
    margin-bottom: $spacing * 2;
  }
}

.mobile__rgu__price,
[class*='mobile__rgu__price--'] {
  position: relative;
  margin-bottom: 1.5rem;

  &[class*='--old'] {
    margin-bottom: 0;
    font-size: 1.4rem;

    span {
      position: relative;
      display: inline-block;

      &::before {
        @include center-y;

        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: $c-anthracite-light;
      }
    }
  }

  &[class*='--new'] {
    display: block;
    color: $c-orange;
  }
}

.mobile__rgu__price--new {
  ::v-deep {
    .price-htva {
      color: $c-promo-card;
    }
  }
}

.configurator__section__header__icon {
  width: 1.2rem;
  height: 2.2rem;
  margin-right: 3.5rem;

  @include mq(m) {
    width: 1.6rem;
    height: 2.9rem;
    margin-right: 4.3rem;
  }
}

.configurator__section__rgus--large {
  @include mq($until: $bp) {
    @include scrollbars(2px, $c-gray-lightest);

    display: flex;
    overflow: auto;
    margin: 0 $wrapper-spacing-s * -1 2.5rem;
    padding: 0 $wrapper-spacing-s 1rem;
  }
}

.mobile__rgu {
  display: flex;
  flex-direction: column;
  align-items: center;

  ::v-deep .selectable-block__check {
    margin-top: auto;
  }

  @include mq($until: $bp) {
    flex-shrink: 0;
    /* stylelint-disable-next-line declaration-no-important */
    width: 16.5rem !important;
    margin-bottom: 0;
  }
}
