
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





































































































$sliderBP: l;

.freelancer-cards {
  overflow: hidden;

  &__speedlines {
    z-index: 4;
    top: 0;
    right: 0;
    width: 100%;

    @include mq($until: l) {
      display: none;
    }
  }

  &__title,
  &__text {
    text-align: center;
  }

  &__text {
    position: relative;
    z-index: 5;
    margin: 0 0 6rem;
  }

  &__items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;

    @include mq($sliderBP) {
      ::v-deep .generic-slider__slides {
        position: relative;
        display: flex;
        justify-content: center;
      }
    }
  }
}
