
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

































































































































































































































































































































































































































































































































































































































































































// To avoid excessive layout shifting when changing slides, fix bullet content width.
// If this solution does not work, we might need to set the width using JS by computing the BBox of all elements
// and setting the width to the largest BBox.
$slider-bullet-content-width: 25rem; // TODO Refactor
$bullet-inline-padding: 3rem;

.slider {
  @extend %list-nostyle;

  display: flex;
}

.slider__item {
  // It does not matter if the first item also has a negative margin since the slider
  // will be positioned using JS anyways.
  // prettier-ignore
  @include fluid(margin-right, (s: -50px, l: -100px));

  flex-shrink: 0;
  cursor: pointer;
  user-select: none;
}

.slider__item__img {
  // prettier-ignore
  @include fluid(width, (s: 250px, l: 500px));

  pointer-events: none; // Prevents user from accidentally dragging image when swiping
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq(m) {
    justify-content: center;
  }
}

.nav__button__icon {
  display: block;
  width: 2rem;
  height: 2rem;
  transform: translateX(2px);
  fill: $white;
}

.nav__button {
  padding: 1rem;
  background-color: transparentize($color: $c-midnight-darkest, $amount: 0.3);
  border: 1px $c-bunting solid;
  border-radius: 50%;

  &.prev {
    .nav__button__icon {
      transform: rotate(180deg) translateX(2px);
    }
  }

  @include mq(m) {
    display: none;
  }
}

.nav__list {
  @extend %list-nostyle;

  position: relative;
  display: flex;
  justify-content: center;
}

.nav__list__item {
  @include mq($until: m) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include mq(m) {
    &:not(:last-of-type) {
      margin-right: 1.5rem;
    }
  }
}

.slider__bullet__infos {
  position: relative;
  display: none;
  width: 2.4rem;
  height: 2.4rem;
  fill: currentColor;
  margin-right: $bullet-inline-padding;
  transition: background-color 250ms $ease-out-quad;

  @include mq(m) {
    display: block;
  }
}

.slider__bullet {
  overflow: hidden;
  padding: 0;
  color: $white;
  background-color: transparentize($color: $c-midnight-darkest, $amount: 0.3);
  border: 1px $c-bunting solid;
  border-radius: 1.2rem;

  &:hover {
    .slider__bullet__infos {
      opacity: 0.75;
    }
  }
}

.slider__bullet__inner {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $c-bunting;
    transform-origin: 0 0;
    transform: scaleX(var(--timer-progress, 0));
    opacity: var(--timer-opacity, 0);
  }
}

.slider__bullet__index {
  @extend %fw-black;

  position: relative;
  display: none;
  padding: 2.5rem $bullet-inline-padding;
  font-family: $ff-alt;
  font-size: 1.5rem;
  line-height: 1.333;

  @include mq(m) {
    display: block;
  }
}

.slider__bullet__text-content {
  position: relative;
  flex-shrink: 0;
  padding: 1.5rem $bullet-inline-padding;
  text-align: center;
  white-space: nowrap;

  @include mq(m) {
    width: $slider-bullet-content-width;
    padding: 0;
    text-align: left;
  }
}

.slider__bullet__title {
  @extend %fw-black;

  font-family: $ff-alt;
  font-size: 1.5rem;
  line-height: 1.333;
}

.slider__bullet__subtitle {
  font-size: 1.3rem;
  opacity: 0.6;
}
