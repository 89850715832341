
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









































$rotate: 3deg;
$delay: 300ms;

.freelancer-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 30rem;
  max-width: 40rem;
  margin: 0 0 $spacing * 2.5;

  &__picture-outer {
    position: relative;
    width: 100%;
    height: 270px;

    @include mq(m) {
      height: 490px;
    }
  }

  &__picture {
    @include image-fit;

    background-color: $c-gray-lightest;
  }

  &__title {
    position: absolute;
    z-index: 20;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 5rem 2rem;
    color: $white;
    font-family: $ff-alt;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.1;
    transition: transform $delay ease;

    @include mq(l) {
      padding: 5rem;
    }
  }

  &__link {
    position: relative;
    z-index: 1;
    display: flex;
    overflow: hidden;
    text-align: center;
    border-radius: 30px;
    transition: transform $delay ease;

    &::before,
    &::after {
      @include get-all-space;

      z-index: 10;
      content: '';
      display: block;
      transition: all 0.2s ease-in-out;
    }

    &::before {
      // prettier-ignore
      background: linear-gradient(0.4deg, rgba(0, 0, 0, 75%) 22%, rgba(0, 0, 0, 5%) 83.57%);
      opacity: 0;

      .homepage-mosaic__card:hover & {
        // prettier-ignore
        opacity: 1;
      }
    }

    &::after {
      // prettier-ignore
      background: linear-gradient(0.4deg, rgba($black, 0.8) 1.97%, rgba($black, 15%) 56.57%);

      .homepage-mosaic__card:hover & {
        opacity: 0;
      }
    }

    &:hover {
      .freelancer-card__title {
        @include mq(l) {
          transform: rotate($rotate);
        }
      }

      @include mq(l) {
        transform: rotate(-$rotate) scale(1.15);
        z-index: 2;
      }
    }
  }

  &__content {
    margin-top: 50px;
  }

  &__cta {
    margin-top: 20px;
  }

  @include mq($until: m) {
    max-width: 90%;
    margin: 0 1rem;
  }

  @include mq(m) {
    margin: 0 $spacing $spacing * 2.5;
  }

  @include mq(l) {
    width: 31%;
    min-width: 25%;
    margin: 0 $spacing / 2;
  }

  @include mq(xl) {
    margin: 0 1%;
  }
}
